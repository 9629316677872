// ----------- import Packs
import React from 'react';
import AsyncStorageLib from '@react-native-async-storage/async-storage';

// ----------- import Internals
import { UseFx } from '#tempUseMorfos';
import { goTo } from '@morfos/routes';
import { TctData, useData } from '#ctData';

// ----------- set Component
export const Public: React.FC = ({ children }) => {
  // ----------- set Public
  const setPublic = async () => {
    const userId = await AsyncStorageLib.getItem('userId');
    if (userId) {
      if (typeAccount === 'app') {
        goTo('cli-prods');
      } else {
        goTo('adm-vehicleList');
      }
    }
    setShow(true);
  };

  // ----------- set Hooks
  const [sttShow, setShow] = React.useState(false);
  const typeAccount = useData(
    (ct: TctData) => ct.projectData.authUser.userInfo?.typeAccount,
  );

  // ----------- set Conditions
  const condChildren = sttShow ? children : null;

  // ----------- set Return
  return (
    <UseFx
      setFunction={setPublic}
      data={ct => ct.projectData.authUser.userInfo}
    >
      {condChildren}
    </UseFx>
  );
};
