// ----------- import Packs
import React from 'react';
import { goTo } from '@morfos/routes';

// ----------- import Internals
import { A2_View } from './A2_TermsView';
import { Public } from '#groups/Public';

// ---------- set Local Types
type Tprops = {};
type TComp = React.FC<Tprops>;

// ----------- set Screen Component
export const A2: TComp = () => {
  // ----------- set Buttons
  const gotoA1 = () => goTo('signin');

  // ----------- set Return
  return (
    <Public>
      <A2_View info={{ btnGoBack: gotoA1, content: { title: `Título` } }} />
    </Public>
  );
};
