// ----------- import Packs
import { doc } from 'firebase/firestore';

// ----------- import Internals
import { googleAuth } from './googleAuth';
import { asyncGetUserDb } from './asyncGetUserDb';
import { addNewUser } from './addNewUser';
import { updateUser } from './updateUser';
import { TuserInfo } from '#dbTypes/TuserInfo';

// ---------- set Async Call
export const asyncGoogleSignin = async () => {
  // ---------- set Google Auth Permission
  const userData = await googleAuth();

  // ---------- set Collection Reference
  const dbInfo = await asyncGetUserDb(userData);
  const { refDb, condNewUser, arrUser } = dbInfo;

  // ---------- set user Object to set or update
  const permissions = ['carlosvsk@gmail.com', 'outroemail@email.com'];
  const condEmail = permissions.some(email => email === userData.email);
  const condTypeAccount = condEmail ? 'adm' : 'app';
  const userDbInfo: TuserInfo = {
    userName: userData.displayName,
    userImg: userData.photoURL,
    userEmail: userData.email,
    typeAccount: condTypeAccount,
  };

  // ---------- set Doc References
  const refDoc = doc(refDb);

  // ---------- set New User
  if (condNewUser) await addNewUser({ userDbInfo, refDoc });

  // ---------- set Update User
  if (!condNewUser) await updateUser({ arrUser, refDb, userDbInfo });

  // ------ return SUCCESS
  const dataSuccess = condNewUser ? userDbInfo : arrUser[0];

  return dataSuccess;
};
