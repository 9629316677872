// ---------- import Packs
import { useData } from '#ctData';
import React from 'react';
import { TuseDataParam } from './types';

// ---------- set Local Types
type Tprops = React.PropsWithChildren<{
  data: TuseDataParam;
  setFunction: Function;
}>;

// ---------- export Function
export default function (props: Tprops) {
  // ---------- set Props
  const { data, setFunction, children } = props;

  // ---------- set Effects
  const fxInitData = () => {
    setFunction();
  };

  // ---------- set Data
  const watchData = useData(data);

  // ---------- set Hooks
  React.useEffect(fxInitData, [watchData]);

  // ---------- set Return
  return <>{children}</>;
}
