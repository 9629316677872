import { A2 } from './';
import { TscRoute } from '@morfos/routes';

const route: TscRoute = {
  path: 'terms',
  routeCode: 'A2',
  component: A2,
  screenTitle: 'Termos',
};

export default {
  route,
};
