// ----------- import Packs
import React from 'react';

// ----------- import Internals
import { A1a_View } from './A1a_UserRegisterView';
import { Private } from '#groups/Private';
import { PrivClient } from '#groups/PrivClient';
import { MobileLimiter } from '#groups/MobileLimiter';

// ---------- set Local Types
type Tprops = {};
type TComp = React.FC<Tprops>;

// ----------- set Screen Component
export const A1a: TComp = () => {
  // ----------- set Return
  return (
    <Private>
      <PrivClient>
        <MobileLimiter>
          <A1a_View />
        </MobileLimiter>
      </PrivClient>
    </Private>
  );
};
