// CRIAR NO FIGMA E COLAR COMPONENTE BASEADO NO SVG TRANSFORMADO PELO SITE:
// https://bit.ly/SVG-RN-6

// ---------- PASTE BELOW
import * as React from 'react';
import Svg, { SvgProps, Path, G, Defs, ClipPath } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={22}
      height={22}
      viewBox="0 0 18 18"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G clipPath="url(#a)" {...props}>
        <Path d="M7.25 14.797H1.64a.6.6 0 0 1-.68-.68v-7.25a.27.27 0 0 0-.13-.25 1.82 1.82 0 0 1-.84-1.54.69.69 0 0 1 .06-.31L1.88.527a.58.58 0 0 1 .59-.38H12a.58.58 0 0 1 .59.39c.58 1.34 1.15 2.69 1.75 4a1.47 1.47 0 0 1 0 1.18 1.78 1.78 0 0 1-.71.87.26.26 0 0 0-.13.25v7.27c0 .47-.18.66-.65.66l-5.6.03Zm1.55-1.06v-3.13h-2a.541.541 0 0 1-.52-.33.52.52 0 0 1 .52-.71h2.5a.55.55 0 0 1 .61.62v3.56h2.61v-6.8a2 2 0 0 1-1.19-.58 1.85 1.85 0 0 1-1.35.59 1.91 1.91 0 0 1-1.35-.59 1.86 1.86 0 0 1-1.33.59 1.9 1.9 0 0 1-1.34-.57 1.91 1.91 0 0 1-1.36.57 1.89 1.89 0 0 1-1.32-.57 5.48 5.48 0 0 1-.57.37 4.702 4.702 0 0 1-.63.22v6.77h2.6v-2a.52.52 0 1 1 1 0v2l3.12-.01Zm-2-12.54-.06.69c-.09 1.06-.19 2.12-.28 3.19a.82.82 0 1 0 1.63.05c0-.32 0-.64-.07-1-.06-.72-.13-1.44-.19-2.16 0-.27 0-.54-.08-.81l-.95.04Zm2 0c.06.65.11 1.29.16 1.92.05.63.12 1.29.16 1.93a.87.87 0 0 0 .73.84.78.78 0 0 0 .87-.93l-.33-1.28c-.2-.79-.41-1.58-.62-2.37 0 0-.06-.11-.1-.11H8.8Zm-3.09 0h-.78c-.25 0-.19.05-.22.17-.31 1.21-.63 2.43-.93 3.64a.57.57 0 0 0 0 .29.82.82 0 0 0 1.6-.17c0-.3.05-.6.07-.89.08-1.02.16-2.02.25-3.05l.01.01Zm-2.05 0H2.9a.18.18 0 0 0-.2.14l-1.59 3.68a.47.47 0 0 0 0 .31.83.83 0 0 0 .86.58.85.85 0 0 0 .73-.84.8.8 0 0 1 0-.26c.17-.67.35-1.35.53-2 .16-.55.28-1.08.42-1.62l.01.01Zm7.19 0c.26 1 .52 2 .77 3 .085.298.145.603.18.91a.83.83 0 0 0 .74.83.82.82 0 0 0 .88-.68.29.29 0 0 0 0-.16l-1.62-3.78a.179.179 0 0 0-.13-.08l-.82-.04Z" />
        <Path d="M5.15 10.608a.52.52 0 0 1-.52-.52.52.52 0 1 1 1 0 .52.52 0 0 1-.48.52Z" />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path
            fill="#fff"
            transform="translate(0 .147)"
            d="M0 0h14.48v14.66H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
