// ----------- set Local Types
import { TAutoItems } from './types';

// ----------- set export Component
export const setSize = (props: TAutoItems) => {
  // ----------- set Props
  const { fill_2, fill_width, fill_height, width, height, parent_row } = props;

  // ----------- set Fill 2 Ways
  const fill2 = { flex: 1, alignSelf: 'stretch', width: '100%' };
  const condFill2 = fill_2 ? fill2 : {};

  // ----------- set Fill 2 Ways
  const fillH = { alignSelf: 'stretch' };
  const fillV = { flex: 1 };

  const condFill = (cond: boolean | undefined) => (cond ? fillH : fillV);

  const condFillH = fill_width ? condFill(!parent_row) : {};
  const condFillV = fill_height ? condFill(parent_row) : {};

  const condWidth = width ? { width } : {};
  const condHeight = height ? { height } : {};

  // ----------- set valTheme
  const objFill = { ...condFill2, ...condFillH, ...condFillV };
  const checkObjFill = Object.keys(objFill).length > 0;
  const condInitFill = checkObjFill ? {} : fill2;

  // ----------- set valTheme
  const valTheme = { ...objFill, ...condInitFill, ...condWidth, ...condHeight };

  // ----------- set return
  return valTheme;
};
