// ----------- import Packs
import React from 'react';
import { Text, TouchableOpacity, ViewStyle, TextStyle } from 'react-native';

// ----------- import Internals
import { useStl } from '#tempUseMorfos';
import { useData } from '#ctData';
import { setUserId } from '../actions/setUserId';

// ----------- set Local Types
export type Titem = {
  name?: string;
  imgUrl?: string;
  desc?: string;
  email?: string;
  address?: string;
  typeAccount?: string;

  containerNumber?: number;
  state?: string;
  docId?: string;
  createdAt?: {
    seconds: number;
    nanoseconds: number;
  };
  localization?: string;
  city?: string;
};

export default ({ itemId }: any) => {
  // ----------- set Data
  const item: Titem = useData('pub.A0a.list.itemsInfo' + '.' + itemId);

  // ----------- stBtns
  const setMockUser = () => setUserId(item);

  return (
    <TouchableOpacity style={stlBtn} onPress={setMockUser}>
      <Text style={stlTxt1}>{item?.userName ?? 'erro'}</Text>
    </TouchableOpacity>
  );
};

// #region :: STYLEs *********
const stlBtn: ViewStyle = {
  ...useStl.btn,
  ...useStl.btnMedium,
  ...useStl.btnPrimary,
  marginBottom: 10,
};
const stlTxt1: TextStyle = { color: '#fff' };

// #endregion *********
