// CRIAR NO FIGMA E COLAR COMPONENTE BASEADO NO SVG TRANSFORMADO PELO SITE:
// https://bit.ly/SVG-RN-6

// ---------- PASTE BELOW
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={19}
      height={15}
      viewBox="0 0 19 15"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M3.61 13.35v-.21H1.4v-.64c0-.09 0-.19.23-.28l3.84-1.82a1.08 1.08 0 0 0 .64-.89 1 1 0 0 0-.41-1 3.06 3.06 0 0 1-.9-1.36 4.91 4.91 0 0 1-.33-1.63V3.33c.03-.305.168-.59.39-.8a3.25 3.25 0 0 1 1.1-.81 3 3 0 0 1 1.32-.33 2.6 2.6 0 0 1 .58.07h.12l.09-.08a4.18 4.18 0 0 1 .91-.57l.53-.24-.6-.25-.12-.05a4.22 4.22 0 0 0-3.49.2 4.69 4.69 0 0 0-1.58 1.24 2.54 2.54 0 0 0-.63 1.59v2.19a6.59 6.59 0 0 0 .43 2.13 4.59 4.59 0 0 0 1 1.67l-3.47 1.65-.14.06-.22.13-.3.21c-.1.088-.187.19-.26.3a.7.7 0 0 0-.13.4v1.37a1.09 1.09 0 0 0 1.12 1.11H4.2l-.28-.39a1.8 1.8 0 0 1-.31-.78Zm14.52-1.59c-.073-.105-.16-.2-.26-.28l-.25-.18a1.277 1.277 0 0 0-.25-.14l-3.18-1.51c.406-.416.714-.919.9-1.47.24-.64.368-1.317.38-2v-2a2.19 2.19 0 0 0-.35-1.13 4.138 4.138 0 0 0-.87-1 4.718 4.718 0 0 0-1.23-.69 3.86 3.86 0 0 0-3.19.18 4.34 4.34 0 0 0-1.07.87l-.1.09a1.92 1.92 0 0 0-.28.29 2.64 2.64 0 0 0-.21.29 2.15 2.15 0 0 0-.37 1.17v2c.017.492.101.98.25 1.45v.15c0 .11.06.22.1.34.08.212.174.42.28.62a4 4 0 0 0 .64.89l-3.13 1.48h-.12l-.21.13a1.842 1.842 0 0 0-.27.19 1.249 1.249 0 0 0-.24.27.75.75 0 0 0-.13.38v1.21a1 1 0 0 0 .31.73 1 1 0 0 0 .74.3h11.14a1 1 0 0 0 1-1v-1.21a.76.76 0 0 0-.03-.42ZM6.32 13.1v-.56c0-.08 0-.16.19-.23L10 10.65a1 1 0 0 0 .6-.82 1 1 0 0 0-.38-.94 2.67 2.67 0 0 1-.82-1.27v-.07a4.7 4.7 0 0 1-.27-1.36v-2a1.21 1.21 0 0 1 .36-.73l.07-.05a3.12 3.12 0 0 1 .91-.67 2.85 2.85 0 0 1 1.2-.29c.31.005.618.062.91.17.3.11.583.26.84.45.223.166.418.365.58.59a1 1 0 0 1 .21.53v2a4.648 4.648 0 0 1-.29 1.49 2.45 2.45 0 0 1-.81 1.21c-.082.08-.155.166-.22.26-.063.102-.11.214-.14.33-.02.12-.02.24 0 .36a1.001 1.001 0 0 0 .6.82l3.52 1.66.1.08v.69l-10.65.01Z"
        {...props}
      />
    </Svg>
  );
}

export default SvgComponent;
