// ----------- import Packs
import { useData } from '#ctData';
import React, { ReactElement } from 'react';

// ----------- import Internals
import { FlatList } from 'react-native';

type Props = {
  data?: string;
  type?: string;
  cols?: number;
  callBackFn?: (itemId: string, noItem: boolean) => ReactElement;
  arrIds?: string[];
  itemComp?: (itemId: string) => ReactElement;
  // ItemComp?: any;
  noItemComp?: () => ReactElement;
};

/**
 * Map Ids and pass them to children components

        'Example to Copy':
        const listCBFn = (itemId: string, noItem: boolean) => {
          const noItemComp = <NoItemView />;
          const infoData = { itemsInfo: 'X0.itemsInfo', itemId };
          const itemComp = <ItemComp key={itemId} infoData={infoData} />;
          return noItem ? noItemComp : itemComp;
        };
        return <UseList data={'X0.itemsList'} callBackFn={listCBFn} />
 */

// ----------- set Default Component
const NoName = ({
  data,
  callBackFn,
  type,
  cols,
  arrIds,
  itemComp,
  noItemComp,
}: Props): any => {
  const condData1 = data ?? '';
  const dataToMap = useData(condData1);
  const condData2 = arrIds ?? dataToMap;
  const condType = type === 'horizontal' ? true : false;
  const condArr = Array.isArray(condData2) && condData2;
  const LEIA =
    'Envie um array de strings de ids pro UseList! Você pode usar o INIT DATA pra preparar esse array!';

  if (!condArr) throw new Error('Não é ARRAY!' + '\n' + LEIA);

  const isEmpty = condArr.length === 0;
  const condItemStr = !isEmpty && typeof condArr[0] !== 'string';

  if (condItemStr) throw new Error('Não é um ARRAY com STRINGS!' + '\n' + LEIA);

  if (isEmpty) {
    // if (!noItemComp) throw new Error('noItemComp not found!');
    if (!callBackFn) return noItemComp();
    return callBackFn('', true);
  }

  type Titem = { item: string };

  // ---------- set TEMP CONDITION
  const condItem = ({ item }: Titem) => {
    if (!itemComp) throw new Error('itemComp not found!');

    // return <CondNoItem />
    return itemComp(item);
  };

  const oldComp = ({ item }: Titem) => {
    if (!callBackFn) throw new Error('callBackFn not found!');

    return callBackFn(item, false);
  };

  // if (!arrIds) throw new Error('arrIds not found!');
  const condData = arrIds ?? dataToMap;

  const condItemPar = itemComp ? condItem : oldComp;

  return (
    <FlatList
      data={condData}
      renderItem={condItemPar}
      horizontal={condType}
      numColumns={cols}
      keyExtractor={item => item}
    />
  );
};

export default NoName;

// ***************************************
// #region :: HOW TO USE IT
// ---------------

/*

<UseList data={'D1.productList'}>
  {(itemId, noItem) =>
    noItem ? <NoItemView /> : <ProdItem itemId={itemId} />
  }
</UseList>

*/

// ---------------
// #endregion
// ***************************************
