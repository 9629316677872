// ---------- import Packs
import React from 'react';
import {
  Linking,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

// ---------- import Internals
import { useStl, useResize } from '#tempUseMorfos';
import { goTo } from '@morfos/routes';
import { AutoItems } from '#common/theme_1/AutoItems';
import { Icon } from '#common/assets/icons/setIcon';

type TstlItm = { [key: string]: ViewStyle | TextStyle };

export const Navigation = () => {
  // ---------- set Hooks
  const { resizeView } = useResize();

  // #region RESPONSIVE STYLES
  const container = resizeView({
    mob: {
      ...useStl.flexCenter,

      backgroundColor: '#ffffff02',

      borderRadius: 16,
      borderColor: '#20162B',
      borderWidth: 2,

      paddingTop: 10,
      paddingBottom: 30,
      marginBottom: 8,
      width: '90%',

      shadowColor: '#fff1',
      shadowOpacity: 0.53,
      shadowRadius: 13.97,

      elevation: 21,
    },
    desk: {
      ...useStl.flexCenter,

      backgroundColor: '#ffffff02',

      borderRadius: 18,
      borderColor: '#20162B',
      borderWidth: 2,

      paddingTop: 20,
      paddingBottom: 30,
      marginBottom: 10,
      width: 500,

      shadowColor: '#fff1',
      shadowOpacity: 0.53,
      shadowRadius: 13.97,

      elevation: 21,
    },
  });

  const stlTxt1 = resizeView({
    mob: { color: '#fff8', fontSize: 20, width: 270 },
    desk: { color: '#fff8', fontSize: 24 },
  });

  const stlTxt2 = resizeView({
    mob: { color: '#fff8', fontSize: 14, width: 200 },
    desk: { color: '#fff8', fontSize: 18, width: 250 },
  });

  // ---------- set Buttons
  const btnGoSignin = () => goTo('signin');
  const gotoDiscord = () => {
    Linking.openURL(url).catch(err => console.error('An error occurred', err));
  };
  const url = 'https://discord.gg/FJaMBWYgwh';

  // ---------- set Return
  return (
    <View style={container}>
      <AutoItems i_space={20} i_center_2>
        <AutoItems i_space={10} i_center_2 fill_width>
          <Text style={[stlTxt1, { textAlign: 'center' }]}>
            Vamos codar o que realmente importa!
          </Text>
          <Text style={[stlTxt2, { textAlign: 'center' }]}>
            Entre com a gente nessa jornada de desenvolvimento.
          </Text>

          <a href={url} target="_blank">
            {/* <TouchableOpacity onPress={gotoDiscord}> */}
            <View
              style={{
                marginTop: 20,
                borderWidth: 1.5,
                borderColor: '#fffa',

                paddingHorizontal: 20,
                paddingVertical: 5,
                // backgroundColor: 'gray',
                borderRadius: 50,
              }}
            >
              <Icon.BtnDiscord fill={'#fffa'} style={{ width: 200 }} />
            </View>
          </a>
          {/* </TouchableOpacity> */}
          {/* <Buttons /> */}
        </AutoItems>

        {/* <List2 /> */}
      </AutoItems>
    </View>
  );
};

export const Buttons = () => {
  const data = [
    { label: 'From the Begining' },
    { label: 'Lastest News', active: true },
  ];

  // ---------- set Hooks
  const { resizeView } = useResize();

  const normal = resizeView({ mob: item1Mob, desk: item1Desk });

  const stlItem = {
    active: { borderColor: '#8927e7', color: '#8927e7' },
    normal,
  };

  return (
    <View style={stlBox}>
      {data.map(item => {
        const condActive = item.active ? { ...stlItem.active } : {};

        return (
          <TouchableOpacity>
            <Text style={[stlItem.normal, condActive]}>{item.label}</Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export const List2 = () => {
  const data = [
    { label: 'all tweets', active: true },
    { label: '#_history' },
    { label: '#_superDevs' },
    { label: '#_showCase' },
  ];

  // ---------- set Hooks
  const { resizeView } = useResize();

  const normal = resizeView({ mob: item2Mob, desk: item2Desk });
  const stlItem = { item2Active, normal };

  return (
    <View style={stlBox}>
      {data.map(item => {
        const condActive = item.active ? { ...stlItem.item2Active } : {};

        return (
          <TouchableOpacity>
            <Text style={[stlItem.normal, condActive]}>{item.label}</Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

// ---------- Styles

const stlBox: ViewStyle = {
  flexDirection: 'row',
};

const item1Mob = {
  paddingHorizontal: 10,
  paddingTop: 6,
  paddingBottom: 10,

  marginHorizontal: 4,

  borderRadius: 50,
  borderColor: '#20162B',
  borderWidth: 2,

  color: '#3a284e',
  fontSize: 10,
};

const item1Desk = {
  paddingHorizontal: 15,
  paddingTop: 6,
  paddingBottom: 10,

  marginHorizontal: 6,

  borderRadius: 50,
  borderColor: '#20162B',
  borderWidth: 2,

  color: '#3a284e',
  fontSize: 12,
};

const item2Mob = {
  marginHorizontal: 6,

  color: '#3a284e',
  fontSize: 10,
};

const item2Desk = {
  marginHorizontal: 6,

  color: '#3a284e',
  fontSize: 12,
};

const item2Active: ViewStyle = {
  borderColor: '#8927e7',
  borderBottomWidth: 2,

  paddingBottom: 4,
  paddingHorizontal: 6,

  color: '#8927e7',
};
