// ---------- import Packs
import { TscRoute } from '@morfos/routes';

// ---------- import Internals
import { A0a } from './';

const route: TscRoute = {
  path: 'tempUsers',
  routeCode: 'A0a',
  component: A0a,
};

const mock = {
  route,

  content: {
    pt: { title: '', msg: '' },
  },

  form: { iptsChanges: {} },

  list: {
    condList: true,

    itemsList: [],
    itemsInfo: {},

    msgError: '',
    showError: false,
  },
};

export default mock;
