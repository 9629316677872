// ---------- Get Actions in Refs

import { setData } from '#ctData';
import { currencyMask } from '#utils';

// ----------- set Field Data
export const fieldsToCTD = (val: string, maskType: string, field: string) => {
  let numInt;

  // ----------- set Field Name in CT Data
  const setField = newVal => {
    const objLimits = {
      cnpj: 14,
      hour: 4,
      phone: 13,
    };
    let limitToWrite = String(newVal).length < objLimits[maskType];

    // limitToWrite &&
    setData(ct => [{ path: `pub.A1a.form.iptsChanges.${field}`, val: newVal }]);
  };

  if (!maskType) {
    setField(val);
  }

  if (maskType === 'currency') {
    numInt = Number(currencyMask(val, true)).toFixed(2);
    setField(numInt);
  }

  if (maskType === 'hour') {
    val = val.substring(0, val.length - 1);
    setField(val);
  }

  if (maskType === 'phone') {
    numInt = Number(currencyMask(val, true));
    setField(numInt);
  }

  if (maskType === 'cnpj') {
    val = val.substring(0, val.length - 1);
    numInt = Number(
      val
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .substring(0, val.length - 1),
    );

    setField(numInt);
  }

  // ----------- No Return
};
