// ----------- import Internals
import { asyncUpdtFB } from './asyncUpdtFB';
import { setDataSuccess } from './setDataSuccess';
import { setError } from './setError';
import { setDataBeforeAsync } from './setDataBeforeAsync';

// ----------- set Local Types
// type Tprops = {};

// ----------- set Main Function
export const updateFB = async (dataToAdd, docId) => {
  // step 1 Before FB Call
  dataToAdd.jumpCad = true;
  setDataBeforeAsync();

  // step 2 Firebase Call
  await asyncUpdtFB(dataToAdd, docId).catch(setError);

  // step 3 After FB Call
  setDataSuccess();
};
