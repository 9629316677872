// ---------- import Packs
import React from 'react';

// ---------- set Local Types
type Tcomp = React.FC<{ setFunction: Function }>;

// ---------- export Function
const useInitFunction = () => {
  const InitFunction: Tcomp = ({ children, setFunction }) => {
    // ---------- set Hooks
    const [sttCondShow, setCondChildren] = React.useState(false);

    // ---------- set Effects
    const fxInitData = () => {
      setFunction();
      setCondChildren(true);
    };
    React.useEffect(fxInitData, []);

    // ---------- set Return
    return sttCondShow ? <>{children}</> : null;
  };

  return { InitFunction };
};

export default useInitFunction;
