// ---------- set Packs
import { setDoc, Timestamp, DocumentReference } from 'firebase/firestore';

// ---------- set Internals
import { TuserInfo } from '#dbTypes/TuserInfo';

// ---------- set Local Types
type Tprops = {
  userDbInfo: TuserInfo;
  refDoc: DocumentReference<TuserInfo>;
};

// ---------- export Function
export const addNewUser = async (props: Tprops) => {
  // ---------- set Props
  const { userDbInfo, refDoc } = props;

  // ---------- set Doc Data to Add
  userDbInfo.createdAt = Timestamp.now();
  userDbInfo.docId = refDoc.id;

  // ---------- set New Doc
  const dataToAdd = { ...userDbInfo };
  await setDoc(refDoc, dataToAdd);
};
