// ----------- import Packs
import React from 'react';
import { goTo } from '@morfos/routes';

// ----------- import Internals
import { A1_View } from './A1_SignInView';
import { Public } from '#groups/Public';
import { googleSignin } from './actions/googleSignin';

// ---------- set Local Types
type Tprops = {};
type TComp = React.FC<Tprops>;

// ----------- set Screen Component
export const A1: TComp = () => {
  // ----------- set Buttons
  const gotoA2 = () => goTo('terms');
  const gotoB1 = () => googleSignin();
  const btnGoToTempUsers = () => goTo('tempUsers');

  // ----------- set Return
  return (
    <Public>
      <A1_View
        info={{ btnGoToTerms: gotoA2, btnSetLogin: gotoB1, btnGoToTempUsers }}
      />
    </Public>
  );
};
