// ----------- import Packs
import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';

// ----------- import Internals
import { useStl, useResize } from '#tempUseMorfos';
import { Card } from '#common/Components/Simple/Card';

// ----------- import Assets
// import src1 from '#images/logo.svg';
// import src2 from '#images/bgshop.jpg';
let src1 = '';
let src2 = '';

// ----------- set Local Types
type Tprops = {
  info: {
    btnGoToTempUsers: () => void;
    btnGoToTerms: () => void;
    btnSetLogin: () => void;
  };
};
type TComp = React.FC<Tprops>;

// ----------- set Default View
export const A1_View: TComp = ({ info, children }) => {
  const { btnGoToTerms, btnGoToTempUsers, btnSetLogin } = info;

  // ----------- set Hooks
  const { resizeView, resizeImg } = useResize();

  // ----------- set Return
  return (
    <View style={stlBodyView}>
      <Image source={src2} style={resizeView(stlBg)} />
      <Card style={[stlCard, resizeView(changeBg)]}>
        <Image source={src1} style={resizeImg(stlLogo)} />

        <Text>Entre usando uma conta</Text>
        <TouchableOpacity
          style={[stlBtCallAct1, useResize(btChangewidth)]}
          onPress={btnSetLogin}
        >
          <Text style={stlBtTxtWhite}>Google</Text>
        </TouchableOpacity>
      </Card>
      <TouchableOpacity onPress={btnGoToTerms}>
        <Text style={{ zIndex: 3 }}>Antes de usar veja os Termos de Uso</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={btnGoToTempUsers}>
        <Text style={{ zIndex: 3 }}>
          Acesso Temporário: Usuários Cadastrados
        </Text>
      </TouchableOpacity>
    </View>
  );
};

// #region :: STYLEs *********
const stlBodyView = [useStl.flex1, useStl.flexCenter, useStl.bgSc];
const stlCard = [
  useStl.card,
  useStl.flexCenter,
  { paddingVertical: 80, minHeight: 400 },
];
const changeBg = {
  desk: { width: '30%' },
  mob: { width: '60%' },
};
const stlLogo = {
  desk: {
    width: '80%',
    height: 80,
    marginVertical: 40,
    resizeMode: 'contain',
  },
  mob: {
    width: '80%',
    height: 80,
    marginVertical: 40,
    resizeMode: 'contain',
  },
};
const stlBg = {
  desk: {
    width: '60%',
    height: 430,
    position: 'absolute',
    resizeMode: 'center',
  },
  mob: {
    width: '100%',
    height: 430,
    position: 'absolute',
    resizeMode: 'center',
  },
};

// --------- set Buttons
export const stlBtCallAct1 = [useStl.btn, useStl.btnLarge, useStl.btnPrimary];
export const btChangewidth = {
  desk: { width: '60%' },
  mob: { width: '60%' },
};
export const stlBtTxtWhite = [useStl.txtPrimaryInverse, { zIndex: 10 }];

// ---------- set Msgs
export const stlMsgError = [{ width: '70%', textAlign: 'center' }];
export const stlMsgTxt = [{ color: '#a2844d', fontWeight: 600 }];

// #endregion *********
