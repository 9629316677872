import React from 'react';
import NoItemView from '#lists/NoItemView';
import { UseCondLoader, UseList } from '#tempUseMorfos';
import { useData } from '#ctData';
import ItemList from './A0a_ItemList';

export const UsersList = () => {
  const arrIds = useData(ct => ct.pub.A0a.list.itemsList);

  return (
    <UseCondLoader data={'pub.A0a.list.condList'}>
      <UseList
        arrIds={arrIds}
        itemComp={itemId => <ItemList itemId={itemId} />}
        noItemComp={() => <NoItemView />}
      />
    </UseCondLoader>
  );
};
