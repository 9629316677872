import { A1 } from './';
import { TscRoute } from '@morfos/routes';

const route: TscRoute = {
  path: 'signin',
  routeCode: 'A1',
  component: A1,
  screenTitle: 'Login',
};

export default {
  route,
};
