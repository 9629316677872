// CRIAR NO FIGMA E COLAR COMPONENTE BASEADO NO SVG TRANSFORMADO PELO SITE:
// https://bit.ly/SVG-RN-6

// ---------- PASTE BELOWimport * as React from "react";
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M15.308 2.434C15.598 1.42 14.584.4 13.566.69c-.733-1.239-2.62-.697-2.62.715v.542a1.41 1.41 0 0 0-1.871 1.33V4.7a3.155 3.155 0 0 0-.776-.165c-1.151-.093-1.903.412-2.657 1.18C-.171 11.633-.008 15.4.002 15.56c.015.238.192.441.531.441.64 0 4.375-.26 9.99-5.875.933-.932 1.159-2.101.778-3.2h1.42a1.41 1.41 0 0 0 1.33-1.872h.542c1.41 0 1.958-1.885.715-2.62ZM9.861 9.462a32.657 32.657 0 0 1-1.955 1.807l-.26-.26a.469.469 0 1 0-.663.663l.195.195C5.5 13.193 3.1 14.726.976 15.025c.184-1.305.854-2.724 1.526-3.853l.832.832a.469.469 0 1 0 .663-.662l-.982-.982c.319-.479.69-.992 1.12-1.536l.857.857a.47.47 0 0 0 .663-.663l-.922-.922c.375-.443.79-.903 1.245-1.38l1.56 1.56a.469.469 0 1 0 .664-.663L6.637 6.048c2.1-1.906 5.294 1.343 3.224 3.414Zm4.732-5.346h-1.77a.469.469 0 0 0-.05.935.47.47 0 0 1-.052.937h-1.918a3.746 3.746 0 0 0-.79-.791v-1.92a.47.47 0 0 1 .936-.051.469.469 0 0 0 .935-.05v-1.77a.47.47 0 0 1 .939-.012.47.47 0 0 0 .791.328c.311-.296.596-.042.65.012.054.055.31.34.014.651a.47.47 0 0 0 .327.792.47.47 0 0 1-.012.939Z"
        {...props}
      />
    </Svg>
  );
}

export default SvgComponent;
