// ----------- import Internals
import { asyncFn1 } from './asyncFn1';
import { setDataSuccess } from './setDataSuccess';
import { setDataBeforeAsync } from './setDataBeforeAsync';
import { setError } from './setError';

// ----------- set Main Function
export const getUserInfo = async (userId: string) => {
  setDataBeforeAsync();

  const dataSuccess = await asyncFn1(userId).catch(setError);

  setDataSuccess({ dataSuccess, userId });

  // ----------- No Return
};
