// ---------- import Packs
import React from 'react';
import { View, ViewStyle } from 'react-native';

// ---------- import Internals
import { useStl } from '#tempUseMorfos';
import { UsersList } from './Components/UsersList';

// ---------- set Local Types
type Tprops = {};
type Tcomp = React.FC<Tprops>;

// ---------- set Screen Component
export const A0a_View: Tcomp = ({ children }) => {
  // ---------- set Return
  return (
    <View style={container}>
      <UsersList />
    </View>
  );
};

// ---------- set Styles
const container: ViewStyle = {
  ...useStl.flexCenter,
  flex: 1,
};
