import { setData, TctData } from '#ctData';
import { TuserInfo } from '#dbTypes/TuserInfo';
import { DocumentSnapshot } from '@firebase/firestore';

// ----------- set Local Types
type Tprops = { dataSuccess: DocumentSnapshot<TuserInfo>; userId: string };

export const setDataSuccess = (props: Tprops) => {
  // ----------- set Props
  const { dataSuccess, userId } = props;

  // ----------- set No Doc Data
  const condNoDoc = !dataSuccess.exists();
  if (condNoDoc) {
    // ----------- change Data if no Doc
    // ----------- set Cond Mock
    const condMock = (ct: TctData) => {
      const mockData = ct.pub.A0a.list.itemsInfo[userId];
      const data1 = [
        { path: 'projectData.authUser.userInfo', val: mockData },
        { path: 'projectData.authUser.loading', val: false },
      ];
      const data2 = [{ path: 'projectData.authUser.loading', val: false }];
      const condMock = mockData ? data1 : data2;

      return condMock;
    };

    setData(condMock);
    return;
  }

  // ----------- set New Data
  const DocData = dataSuccess.data();

  // ----------- set Data
  const dataAfterAsync = [
    { path: 'projectData.authUser.userInfo', val: DocData },
    { path: 'projectData.authUser.loading', val: false },
  ];

  // ----------- change Data
  setData(dataAfterAsync);

  // ----------- No Return
};
