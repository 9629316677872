// ---------- import Packs
import React from 'react';

// ---------- import Internals
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { Icon } from '#icons';
import { setData } from '#ctData';

// ----------- set Local Types
type Tinfo = {
  label: string;
  field: string;
  path: string;
};
type Tstl = {
  checkColor: string;
  iconColor: string;
  labelStyle?: string;
};
type Tprops = { infoData: Tinfo; infoStl: Tstl };
type TComp = React.FC<Tprops>;

export const IptCheck: TComp = ({ infoData, infoStl }) => {
  // ---------- set Effects
  const fxToggl = () => setToCTD();

  // ---------- set Params
  const { label, field, path } = infoData;
  const { checkColor, iconColor, labelStyle } = infoStl;

  // ---------- set Hooks
  const [sttToggl, setToggl] = React.useState(false);
  React.useEffect(fxToggl, [sttToggl]);

  // ---------- set Functions
  const btnToggl = () => setToggl(!sttToggl);
  const setToCTD = () => {
    setData({ path: `${path}.${field}`, val: sttToggl });
  };

  return (
    <View style={styles.WrapperCheckBox}>
      <TouchableOpacity style={styles.Row} onPress={btnToggl}>
        <View
          style={[
            styles.CheckBox,
            {
              backgroundColor: sttToggl ? checkColor : '#fff',
              borderColor: sttToggl ? checkColor : '#ccc',
            },
          ]}
        >
          {sttToggl ? (
            <Icon.Check fill={iconColor ? iconColor : '#fff'} />
          ) : null}
        </View>
        <Text style={[styles.LabelCheck, labelStyle]}>{label ?? ''}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  Row: {
    flexDirection: 'row',
    marginTop: 14,
  },
  CheckBox: {
    width: 22,
    height: 22,
    borderWidth: 1,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  WrapperCheckBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  LabelCheck: {
    color: '#444',
    marginLeft: 6,
  },
});
