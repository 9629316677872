// ----------- import Packs
import React from 'react';
import { View, Text } from 'react-native';

// ----------- import Internals
import { useResize } from '#tempUseMorfos';
import { shadowLess } from '#stl';

// ----------- export Component
export const MobileLimiter: React.FC = ({ children }) => {
  // ----------- set Hooks
  const { resizeView } = useResize();

  // ----------- set Styles
  const stlLimiter = {
    desk: {
      width: 500,
      flex: 1,
      alignSelf: 'center',
      ...shadowLess,
    },
    mob: {
      flex: 1,
    },
  };

  // ----------- set Return
  return <View style={resizeView(stlLimiter)}>{children}</View>;
};
