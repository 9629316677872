// ----------- import Packs
import React from 'react';

// ----------- import Internals
import { Connect } from '#ctData';
import { ConnectedRouter } from './config/routes/ConnectedRouter';
// import { SetFonts } from '#common/theme_1/fonts';

// ----------- set Component
export default () => {
  return (
    <Connect>
      {/* <SetFonts> */}
        <ConnectedRouter />
      {/* </SetFonts> */}
    </Connect>
  );
};
