// ----------- import Internals

import { setData } from '#ctData';
import { goTo } from '@morfos/routes';
import AsyncStorageLib from '@react-native-async-storage/async-storage';

// ----------- set Main Function
export const setLogout = async () => {
  await AsyncStorageLib.removeItem('userId');

  // ----------- set Clean User
  setData({ path: 'projectData.authUser.userInfo', val: null });
};
