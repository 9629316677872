// ----------- import Packs
import { collection, doc, updateDoc } from 'firebase/firestore';

// ----------- import Internals
import { firestoreInit } from '#firebase';

export const asyncUpdtFB = async (dataToAdd, docId) => {
  // ---------- set Data Base Reference
  const refDb = collection(firestoreInit, 'users');
  const refDoc = doc(refDb, docId);

  // ---------- set Async Call
  await updateDoc(refDoc, dataToAdd);
};
