// ---------- Use Snippet...

// ---------- import Packs
import React from 'react';

// ---------- import Internals
import { A0_View } from './A0_LandingPageView';
import { Public } from '#groups/Public';
import './style.css';

// ---------- set Local Types
type Tprops = {};
type Tcomp = React.FC<Tprops>;

// ---------- set Screen Component
export const A0: Tcomp = () => {
  // ---------- set Routes

  // ---------- set Return
  const infoToView = {};
  return (
    <Public>
      <A0_View info={infoToView} />
    </Public>
  );
};
