// ----------- import Packs
import React from 'react';
import { View, ViewStyle } from 'react-native';

// ----------- set Local Types
type Tprops = { style: ViewStyle };
type TComp = React.FC<Tprops>;

// ----------- set Component
export const Card: TComp = ({ style, children }) => {
  // ----------- set Return
  return <View style={style}>{children}</View>;
};
