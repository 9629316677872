// ---------- import Packs
import React from 'react';

// ---------- import Internals
import { Navigation } from './Components/Navigation';
import { Header } from './Components/Header';
import { AutoItems } from '#common/theme_1/AutoItems';

// ---------- set Local Types
type Tprops = { info?: {} };
type Tcomp = React.FC<Tprops>;

// ---------- set Screen Component
export const A0_View: Tcomp = () => {
  React.useEffect(() => {
    // const favicon = document.createElement('link');
    // const favicon = document.getElementById("favicon");
    // if(!favicon) throw new Error('favicon not found!')

    // favicon.rel = 'favicon';
    // favicon.href = 'src/common/assets/images/favicon-32x32.png';
    // document.head.appendChild(favicon);

    const twitterScript = document.createElement('script');
    twitterScript.src = 'https://platform.twitter.com/widgets.js';
    twitterScript.async = true;
    document.body.appendChild(twitterScript);

    return () => {
      // document.head.removeChild(favicon);

      document.body.removeChild(twitterScript);
    };
  }, []);

  // ---------- set Return
  return (
    <div className="bgSvg">
      {/* <View
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          backgroundColor: '#070608',
        }}
      >
        {[1, 2, 3, 4, 5, 6, 7, 8].map(i => (
          <Icon.BgImg
            style={{
              height: '100%',
              width: '100%',
              opacity: 0.01,
            }}
          />
        ))}
      </View> */}
      {/* </View> */}

      <AutoItems bgColor="transparent" i_center_h>
        <Header />

        <Navigation />

        <AutoItems i_center_h pad_h={30}>
          <a
            class="twitter-timeline"
            data-lang="en"
            data-width="500"
            data-height="600"
            data-dnt="true"
            data-chrome="transparent nofooter noscrollbar"
            // data-chrome="transparent nofooter noscrollbar noheader noborders"
            data-theme="dark"
            // data-link-color="#ffcc00"
            // data-link-color="#ff0000"
            href="https://twitter.com/_flaxboll_br"
          >
            Tweets by @_flaxboll
          </a>
        </AutoItems>
        {/* <Footer /> */}
      </AutoItems>
    </div>
  );
};
