// ----------- import Packs
import React from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';

// ----------- import Internals
import { updateFB } from './actions/updateFB';
import { fieldsToCTD } from './actions/fieldsToCTD';
import { infoStl } from '#forms/IptTxt/ipTheme';
import { useStl } from '#tempUseMorfos';
import { IptTxt } from '#forms/IptTxt';
import { IptCheck } from '#forms/IptCheck';
import { useData } from '#ctData';

// ----------- import Assets
import { primaryColor } from '#stl';
// import defaultImg from '#images/default.jpg';
let defaultImg = '';

// ----------- set Local Types
type Tprops = {};
type TComp = React.FC<Tprops>;

// ----------- set Default View
export const A1a_View: TComp = () => {
  // ----------- set Data
  const condLoader = useData('pub.A1a.list.condList');
  const dataToAdd = useData('pub.A1a.form.iptsChanges');
  const { userImg, docId } = useData('projectData.authUser.userInfo');
  const condImg = userImg ?? defaultImg;

  // ------------- set Functions
  const getTxt = (txt: string, maskType: string, field: string) =>
    fieldsToCTD(txt, maskType, field);
  const setFB = () => updateFB(dataToAdd, docId);

  // ----------- set Return
  return (
    <View style={styles.mainBox}>
      <Image source={condImg} style={styles.image} />
      <View>
        <IptTxt
          infoData={{
            field: 'userName',
            label: 'Nome:',
            pHolder: 'Ex: João da Silva',
            infoStl,
          }}
          getData={getTxt}
        />
        <IptTxt
          infoData={{
            field: 'userMail',
            label: 'E-mail:',
            pHolder: 'Ex: joaosilva@gmail.com',
            infoStl,
          }}
          getData={getTxt}
        />
        <IptTxt
          infoData={{
            field: 'userPhone',
            label: 'Telefone:',
            pHolder: 'Ex: 9999-9999',
            infoStl,
          }}
          getData={getTxt}
        />
        <IptTxt
          infoData={{
            field: 'userCPF',
            label: 'CPF:',
            pHolder: 'Ex: 999.999.999-99',
            infoStl,
          }}
          getData={getTxt}
        />
        <View style={styles.checkbox}>
          <IptCheck
            infoStl={{
              checkColor: primaryColor,
              iconColor: '#fff',
            }}
            infoData={{
              path: 'pub.A1a.form.iptsChanges',
              label: 'Tenho 18 anos ou mais de idade',
              field: 'yearOldCheck',
            }}
          />
        </View>
        <View style={styles.checkbox}>
          <IptCheck
            infoStl={{
              checkColor: primaryColor,
              iconColor: '#fff',
            }}
            infoData={{
              path: 'pub.A1a.form.iptsChanges',
              label: 'Aceito os termos de uso',
              field: 'termsCheck',
            }}
          />
        </View>
        <View>
          <ActivityIndicator
            size="large"
            color="#ff3333"
            animating={condLoader}
          />
        </View>

        <View style={styles.btnBox}>
          <TouchableOpacity style={btnConfirm} onPress={setFB}>
            <Text style={stlTxtLight}>Confirmar</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

// #region :: STYLEs *********

const styles = StyleSheet.create({
  mainBox: {
    ...useStl.flex1,
    ...useStl.flexCenter,
  },

  checkbox: {
    ...useStl.flexRow,
  },

  image: {
    width: 150,
    height: 150,
    borderRadius: 100,
    resizeMode: 'cover',
  },

  btnBox: {
    width: '100%',
    padding: 2,
    alignItems: 'center',
  },
});

// --------- set Buttons
export const stlBtCallAct1 = [useStl.btn, useStl.btnLarge, useStl.btnPrimary];
export const btChangewidth = {
  desk: { width: '60%' },
  mob: { width: '60%' },
};

const btnConfirm = {
  ...useStl.btn,
  ...useStl.btnPrimary,
  ...useStl.btnMedium,
  backgroundColor: '#000033',
};

const stlCancel = {
  ...useStl.btn,
  ...useStl.btnPrimary,
  ...useStl.btnMedium,
  backgroundColor: '#ff3333',
};

export const stlBtTxtWhite = [useStl.txtPrimaryInverse, { zIndex: 10 }];
const stlTxtLight = { color: '#FFF' };

// ---------- set Msgs
export const stlMsgError = { width: '70%', textAlign: 'center' };
export const stlMsgTxt = { color: '#a2844d', fontWeight: 600 };

// #endregion *********
