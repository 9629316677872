// ---------- set Packs
import { collection, getDocs } from 'firebase/firestore';
import { where, query } from 'firebase/firestore';
import { User } from 'firebase/auth';

// ---------- set Internals
import { firestoreInit, fbType } from '#firebase';
import { TuserInfo } from '#dbTypes/TuserInfo';

// ---------- export Function
export const asyncGetUserDb = async (userData: User) => {
  // ---------- set DataBase Reference
  const refDb = collection(firestoreInit, 'users')
    // ---------- set Type to FirebaseData
    .withConverter(fbType<TuserInfo>());

  // ---------- set Data Query
  const filter1 = where('userEmail', '==', userData.email);
  const refQuery = query(refDb, filter1);

  // ---------- get all Users Found
  const users = await getDocs(refQuery);

  // ---------- set Users Found if any
  const arrUser: TuserInfo[] = [];
  users.forEach(doc => {
    const obj = doc.data();
    return arrUser.push(obj);
  });
  const condNewUser = arrUser.length === 0;

  // ---------- set Return
  return { refDb, condNewUser, arrUser };
};
