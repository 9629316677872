// ----------- import Packs
import { doc, getDoc } from 'firebase/firestore';

// ----------- import Internals
import { fbType, firestoreInit } from '#firebase';
import { TuserInfo } from '#dbTypes/TuserInfo';

// ----------- export Function
export const asyncFn1 = async (userId: string) => {
  // ---------- set Data Ref
  const dbRef = doc(firestoreInit, 'users', userId)
    // ---------- set Type to FirebaseData
    .withConverter(fbType<TuserInfo>());

  // ---------- get all collection data
  const dataSuccess = await getDoc(dbRef);

  // ----------- set Return
  return dataSuccess;
};
