// ----------- import Packs
import React from 'react';

// ----------- import Internals
import AsyncStorageLib from '@react-native-async-storage/async-storage';
import { goTo } from '@morfos/routes';
import { useData } from '#ctData';
import { getUserInfo } from './actions/getUserInfo';

// ----------- export Component
export const Private: React.FC = ({ children }) => {
  // ----------- set Effects
  const fxGetUser = () => {
    // ----------- set Async Function
    const setPrivate = async () => {
      const userId = await AsyncStorageLib.getItem('userId');

      if (userId && !userData) {
        getUserInfo(userId);
        setShow(true);
      }
      if (userId === null) goTo('signin');
    };

    // ----------- call Async Function
    setPrivate();
  };

  // ----------- set Data
  const userData = useData(ct => ct.projectData.authUser.userInfo);

  // ----------- set Hooks
  const [sttShow, setShow] = React.useState(false);
  React.useEffect(fxGetUser, [userData]);

  // ----------- set Conditions
  const condChildren = sttShow ? children : null;

  // ----------- set Return
  return <>{condChildren}</>;
};
