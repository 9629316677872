// ----------- import Packs
import { initializeApp } from 'firebase/app';

// ----------- project Packs
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

// ----------- placeHolder Packs
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
// import { getFunctions } from 'firebase/functions';
// import { getMessaging } from 'firebase/messaging';

// ---------- chave temporaria
const fbConfig = {
  apiKey: 'AIzaSyCQQ6hGHmwAHlyjqbphx7Mn61ZWd708vBg',
  authDomain: 'flaxboll.firebaseapp.com',
  projectId: 'flaxboll',
  storageBucket: 'flaxboll.appspot.com',
  messagingSenderId: '707022923688',
  appId: '1:707022923688:web:62d8bd5e16f18da3d69f9d',
  measurementId: 'G-FEXXR0BBNQ',
};

// ----------- start Firebase
export const fbInit = initializeApp(fbConfig);
export const analytics = getAnalytics(fbInit);

// ----------- project Packs
export const firestoreInit = getFirestore(fbInit);

// ----------- placeHolder Packs
export const auth = getAuth(fbInit);
export const storage = getStorage(fbInit);
// export const functions = getFunctions(fbInit);
// export const messaging = getMessaging(fbInit)

// ---------- set Type to FirebaseData
export const fbType = <T>() => ({
  toFirestore: (data: T) => data,
  fromFirestore: (snap: any) => snap.data() as T,
});
