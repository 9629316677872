import { A1a } from './';
import { TscRoute } from '@morfos/routes';

const route: TscRoute = {
  path: 'user-register',
  routeCode: 'A1a',
  component: A1a,
  screenTitle: 'Register',
};

const list = {
  condList: false,
};

export default {
  route,
  list,
};
