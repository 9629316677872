// ---------- import Packs
import React from 'react';
import { ActivityIndicator, View, ViewStyle } from 'react-native';

// ---------- import Internals
// import { primaryColor } from '../styles';
import { useData } from '#ctData';
import { TuseDataParam } from './types';

// ---------- set Local Types
type Tprops = { data: TuseDataParam } & TloaderProps;
type TloaderProps = React.ComponentProps<typeof ActivityIndicator>;

// ---------- export Function
export default function (props: Tprops) {
  // ---------- set Props
  const { data, children } = props;

  // ---------- set Data
  const showValidation = useData(data);

  // ---------- set Return
  return showValidation ? <>{children}</> : <CompLoader {...props} />;
}

// ---------- set Loader Component
const CompLoader: React.FC<TloaderProps> = props => {
  // ---------- set Props
  const { size = 25, color = 'red' } = props;

  // ---------- set Return
  return (
    <View style={stlCenter}>
      <ActivityIndicator size={size} color={color} />
    </View>
  );
};

// ---------- set Styles
// const stlCenter = [useStl.flexCenter, useStl.flex1];
const stlCenter: ViewStyle = {
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
};
