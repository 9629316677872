// ----------- import Internals
import { asyncGetUsers } from './asyncGetUsers';
import { setDataSuccess } from './setDataSuccess';
import { setDataBeforeAsync } from './setDataBeforeAsync';
import { setError } from './setError';

// ----------- set Main Function
export const getUsers = async () => {
  setDataBeforeAsync();

  const dataSuccess = await asyncGetUsers().catch(setError);

  setDataSuccess(dataSuccess);

  // ----------- No Return
};
