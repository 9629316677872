// ----------- import Packs
import { ViewStyle } from 'react-native';

// ----------- set Local Types
type Tprops = {
  style?: ViewStyle | ViewStyle[];
  rowView: {};
  sizeStl: {};
  alignView: {};
  paddingStl: {};
  bgColor?: string;
  red?: boolean;
};

// ----------- set export Component
export const setView = (props: Tprops) => {
  // ----------- set Props
  const { style, rowView, sizeStl } = props;
  const { alignView, paddingStl, bgColor, red } = props;

  // ----------- set View
  const initStyles = { alignItems: 'start' };
  const condBgColor = bgColor ? { backgroundColor: bgColor } : {};
  const condRed = red ? { backgroundColor: 'red' } : condBgColor;

  // ----------- set Style if Array or not
  let newStl = {};
  if (Array.isArray(style)) {
    style.map(item => {
      let key: keyof ViewStyle;
      for (key in item) {
        const element = item[key];
        // @ts-ignore
        newStl[key] = element;
      }
    });
  } else {
    newStl = { ...style };
  }
  const themeStyle = { ...newStl, ...condRed };

  // ----------- set Return
  const arr1 = [initStyles, sizeStl, rowView, alignView];
  const viewStl = [...arr1, paddingStl, themeStyle];
  return viewStl;
};
