import { TuserInfo } from '#dbTypes/TuserInfo';
import { goTo } from '@morfos/routes';
import { setLogout } from '../../common/Components/ScGroups/NavControl/actions/setLogout';

// ----------- set Project Data Object
export const projectData: TprjDt = {
  sideRight: {
    open: false,

    arrMenu2: [
      {
        icon: 'Carrot',
        label: 'Lista de Produtos',
        condActive: 'adm-prodList',
        onPress: () => goTo('adm-prodList'),
      },
      {
        icon: 'Users',
        label: 'Lista de Clientes',
        condActive: 'adm-clientList',
        onPress: () => goTo('adm-clientList'),
      },
      {
        icon: 'Shop',
        label: 'Lista de Lojas',
        condActive: 'adm-vehicleList',
        onPress: () => goTo('adm-vehicleList'),
      },
      {
        icon: 'Logout',
        label: 'Sair',
        onPress: () => setLogout(),
      },
    ],
  },
  authUser: { loading: false },
};
type TprjDt = {
  sideRight: {
    open: boolean;
    component?: any;

    arrMenu2: {
      icon: string;
      label: string;
      condActive?: string;
      onPress: Function;
    }[];
  };
  authUser: {
    userInfo?: TuserInfo;
    userId?: string;
    loading: boolean;
  };
  // navControl?: { bigSc?: React.ReactNode };
};
