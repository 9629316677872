// ----------- import Packs
import React from 'react';
import { View } from 'react-native';
import { ErrorBoundary } from '../ErrorBoundary';

// ----------- import Internals
import { setAlign } from './setAlign';
import { setChildren } from './setChildren';
import { setGap } from './setGap';
import { setPadding } from './setPadding';
import { setRow } from './setRow';
import { setSize } from './setSize';
import { setView } from './setView';
import { TAutoItems } from './types';

export type TComp = React.FC<TAutoItems>;

// ----------- set export Component
export const AutoItems: TComp = props => {
  // ----------- set Props
  const { children, style, i_space, i_row, bgColor, red, setRef } = props;

  // ----------- set Styles Steps
  const rowView = setRow(i_row);
  const gapStl = setGap({ i_space, i_row });
  const sizeStl = setSize(props);
  const paddingStl = setPadding(props);
  const alignView = setAlign(props);

  // ----------- set Styles
  const obj1 = { style, rowView, sizeStl, bgColor };
  const infoView = { ...obj1, alignView, paddingStl, red };
  const viewStl = setView(infoView);
  const childrenStl = { children, gapStl, i_row };

  // ----------- set New Children
  // const newChildren = gapStl ? setChildren(childrenStl) : children;
  const newChildren = setChildren(childrenStl);

  // ----------- set Return
  return (
    <ErrorBoundary>
      <View style={viewStl} ref={setRef}>
        {newChildren}
      </View>
    </ErrorBoundary>
  );
};
