// ----------- import Packs
import React from 'react';

// ----------- import Internals
import { useData } from '#ctData';
import { goTo } from '@morfos/routes';

// ---------- set Local Types
type Tprops = {};
type Tcomp = React.FC<Tprops>;

// ----------- export Component
export const PrivClient: Tcomp = ({ children }) => {
  // ----------- set Data
  const userData = useData(ct => ct.projectData.authUser.userInfo);
  if (!userData) return <></>;

  const { typeAccount } = userData;
  const isCli = typeAccount === 'app';

  if (!isCli) goTo('adm-vehicleList');

  // ----------- set Return
  return <>{children}</>;
};
