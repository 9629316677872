// ---------- set Packs
import { doc, updateDoc, CollectionReference } from 'firebase/firestore';

// ---------- set Internals
import { TuserInfo } from '#dbTypes/TuserInfo';

// ---------- set Local Types
type Tprops = {
  arrUser: TuserInfo[];
  refDb: CollectionReference<TuserInfo>;
  userDbInfo: TuserInfo;
};

// ---------- export Function
export const updateUser = async (props: Tprops) => {
  // ---------- get Props
  const { arrUser, refDb, userDbInfo } = props;

  // ---------- get Doc to Update
  const currUser = arrUser[0].docId;
  const userDoc = doc(refDb, currUser);

  // ---------- set Update Doc
  const dataToUpdate = { ...userDbInfo };
  await updateDoc(userDoc, dataToUpdate);
};
