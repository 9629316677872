// ---------- import Packs
import AsyncStorage from '@react-native-async-storage/async-storage';

// ---------- import Internals
import { goTo } from '@morfos/routes';
import { Titem } from '../../Components/A0a_ItemList';
import { getCtData } from './getCtData';

export const setUserId = async (item: Titem) => {
  // ----------- set Data
  const docId = item.docId;
  const typeAccount = item.typeAccount;

  if (docId) {
    await AsyncStorage.setItem('userId', docId);

    if (typeAccount === 'app') {
      goTo('cli-prods');
    } else {
      goTo('adm-vehicleList');
    }
  }
};
