// ----------- import Packs
import React from 'react';
import { View } from 'react-native';

// ----------- set Local Types
type Tinfo = React.PropsWithChildren<{ gapStl: {}; i_row?: boolean }>;

// ----------- set Function to Export
export const setChildren = (info: Tinfo) => {
  // ----------- set Props
  const { children } = info;

  // ----------- set New Children
  // @ts-ignore
  const ChildrenArr: React.ReactElement[] = React.Children.toArray(children);
  const NewChildren = ChildrenArr.map(childMap(info));

  // ----------- set Return
  return NewChildren;
};

// ----------- set Each Child Change
const childMap = (info: Tinfo) => (child: React.ReactElement, idx: number) => {
  // ----------- set Props
  const { children, gapStl, i_row } = info;

  // ----------- set Condition
  const checkItems = React.Children.count(children) > 1;
  const condGap = checkItems && idx > 0;
  const newChild = React.cloneElement(child, { parent_row: i_row && true });

  // ----------- set Returns
  if (!condGap) return newChild;

  return (
    <React.Fragment key={idx}>
      <View style={[gapStl]} />
      {newChild}
    </React.Fragment>
  );
};
