// CRIAR NO FIGMA E COLAR COMPONENTE BASEADO NO SVG TRANSFORMADO PELO SITE:
// https://bit.ly/SVG-RN-6

// ---------- PASTE BELOWimport * as React from 'react';
import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={250}
    height={30}
    // viewBox="0 0 1000 120"
    // fill="red"
    {...props}
  >
    <G fill="none" stroke="#ffffff" strokeWidth={10}>
      <Path d="M-500 75s125-30 250-30S0 75 0 75s125 30 250 30 250-30 250-30 125-30 250-30 250 30 250 30 125 30 250 30 250-30 250-30" />
      <Path d="M-500 45s125-30 250-30S0 45 0 45s125 30 250 30 250-30 250-30 125-30 250-30 250 30 250 30 125 30 250 30 250-30 250-30m-2000 60s125-30 250-30S0 105 0 105s125 30 250 30 250-30 250-30 125-30 250-30 250 30 250 30 125 30 250 30 250-30 250-30" />
      <Path d="M-500 15s125-30 250-30S0 15 0 15s125 30 250 30 250-30 250-30 125-30 250-30 250 30 250 30 125 30 250 30 250-30 250-30" />
      <Path d="M-500-15s125-30 250-30S0-15 0-15s125 30 250 30 250-30 250-30 125-30 250-30 250 30 250 30 125 30 250 30 250-30 250-30M-500 135s125-30 250-30S0 135 0 135s125 30 250 30 250-30 250-30 125-30 250-30 250 30 250 30 125 30 250 30 250-30 250-30" />
    </G>
  </Svg>
);

export default SvgComponent;
