// ----------- import Internals
import { TAutoItems } from './types';

// ----------- set export Component
export const setAlign = (props: TAutoItems) => {
  // ----------- set Props
  const { i_center_2, i_center_h, i_left_h, i_right_h, i_row } = props;
  const { i_center_v, i_top_v, i_bottom_v, i_space_between } = props;

  // ----------- set Conditional Alignments
  const condHorizontal = i_row ? 'justifyContent' : 'alignItems';
  const condVertical = i_row ? 'alignItems' : 'justifyContent';
  const condSpaceBtw = !i_row ? 'alignItems' : 'justifyContent';

  const hvCenter = { justifyContent: 'center', alignItems: 'center' };
  const condHVC = i_center_2 ? hvCenter : {};

  // ----------- set Horizontal Alignments
  const hCenter = { [condHorizontal]: 'center' };
  const condHC = i_center_h ? hCenter : {};

  const hLeft = { [condHorizontal]: 'flex-start' };
  const condHL = i_left_h ? hLeft : {};

  const hRight = { [condHorizontal]: 'flex-end' };
  const condHR = i_right_h ? hRight : {};

  // ----------- set Vertical Alignments
  const vCenter = { [condVertical]: 'center' };
  const condVC = i_center_v ? vCenter : {};

  const vTop = { [condVertical]: 'flex-start' };
  const condVT = i_top_v ? vTop : {};

  const vBottom = { [condVertical]: 'flex-end' };
  const condVB = i_bottom_v ? vBottom : {};

  // ----------- set Space Between
  const spacBtw = { [condSpaceBtw]: 'space-between' };
  const condSB = i_space_between ? spacBtw : {};

  // ----------- set Return
  const obj1 = { ...condHVC, ...condHC, ...condHL, ...condHR };
  const alignView = { ...obj1, ...condVC, ...condVT, ...condVB, ...condSB };

  return alignView;
};
