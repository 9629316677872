// ----------- import Packs

// ----------- import Internals
import { setData } from '#ctData';
import { TuserInfo } from '#dbTypes/TuserInfo';

// ----------- set Function
export const setDataSuccess = async (dataSuccess: TuserInfo) => {
  const change1 = { path: 'pub.A0a.list.condList', val: true };
  const change2 = {
    path: 'pub.A0a.list.itemsInfo',
    val: dataSuccess.itemsInfo,
  };
  const change3 = {
    path: 'pub.A0a.list.itemsList',
    val: dataSuccess.itemsList,
  };
  // ----------- set Data
  const dataAfterAsync = [change1, change2, change3];

  // ----------- change Data
  setData(dataAfterAsync);

  // ----------- No Return
};
