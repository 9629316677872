import { setData, TctData } from '#ctData';
import { goTo } from '@morfos/routes';

export const setDataSuccess = () => {
  // ----------- set Data
  const dataAfterAsync = { path: 'adm.D1.form.currStatus', val: 'msgOk' };

  // ----------- change Data
  setData(dataAfterAsync);
  setData({ path: 'pub.A1a.list.condList', val: false });
  goTo('cli-prods');
};
