// ---------- import Packs
import React from 'react';
import { Image, Text, TouchableOpacity, View, ViewStyle } from 'react-native';

// ---------- import Internals
import { useStl, useResize } from '#tempUseMorfos';
import { goTo } from '@morfos/routes';

export const Header = () => {
  // ---------- set Hooks
  const { resizeView } = useResize();

  // #region RESPONSIVE STYLES
  const stlTxt = resizeView({
    mob: { color: '#fffa', fontSize: 40 },
    desk: { color: '#fffa', fontSize: 60 },
  });

  const topLimit = resizeView({
    mob: {
      ...useStl.flexRow,
      ...useStl.flexCenter,
      // width: 1100,
      padding: 10,
    },
    desk: {
      ...useStl.flexRow,
      ...useStl.flexCenter,
      // width: 1100,
      padding: 20,
    },
  });

  // ---------- set Buttons
  const btnGoSignin = () => goTo('signin');

  // ---------- set Return
  return (
    <View style={navbar}>
      <View style={topLimit}>
        {/* <Text style={[stlTxt, { fontFamily: 'Montserrat_500Medium' }]}>
          FLAXBOLL
        </Text> */}
        <Text style={[stlTxt, { fontWeight: '700', fontFamily: 'Montserrat' }]}>FLAXBOLL</Text>
        {/* <Text style={[stlTxt, { fontFamily: 'Lato_700Bold' }]}>FLAXBOLL</Text> */}
        {/* <Text class-name=''>flaxboll</Text> */}
      </View>
    </View>
  );
};

// ---------- Styles
const navbar: ViewStyle = {
  flex: 1,
  ...useStl.flexCenter,
};

const btnLogin = {
  marginLeft: 10,
  ...useStl.btn,
  ...useStl.btnPrimary,
  ...useStl.btnSmall,
  backgroundColor: '#3B7290',
};
