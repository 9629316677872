// ----------- set Local Types
type TsetGapProps = { i_space?: number; i_row?: boolean };

// ----------- set export Component
export const setGap = ({ i_space, i_row }: TsetGapProps) => {
  // ----------- set No Space
  if (!i_space) return false;

  // ----------- set Gap Direction
  const gapProps = i_row ? { width: i_space } : { height: i_space };

  // ----------- set Return
  const gapVal = { ...gapProps, backgroundColor: 'transparent' };

  return gapVal;
};
