// ---------- Use Snippet...

// ---------- import Packs
import { TscRoute } from '@morfos/routes';

// ---------- import Internals
import { A0 } from './';

const route: TscRoute = {
  path: 'feed',
  routeCode: 'A0',
  component: A0,
};

export default {
  route,

  content: {
    pt: { title: '', msg: '' },
  },

  form: { iptsChanges: {} },

  list: {
    condList: false,

    itemsList: [''],
    itemsInfo: {},

    msgError: '',
    showError: false,
  },
};
