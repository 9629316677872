// ---------- import Packs
import React from 'react';

// ---------- import Internals
import { A0a_View } from './A0a_TempUsersView';
import { Public } from '#groups/Public';
import { useInitFunction } from '#tempUseMorfos';
import { getUsers } from './actions/getUsers';

// ---------- set Local Types
type Tprops = {};
type Tcomp = React.FC<Tprops>;

// ---------- set Screen Component
export const A0a: Tcomp = () => {
  // ---------- set Hooks
  const { InitFunction } = useInitFunction();

  // ---------- set Return
  return (
    <Public>
      <InitFunction setFunction={getUsers}>
        <A0a_View />
      </InitFunction>
    </Public>
  );
};
