import A0 from './A0_LandingPage/centralData';
import A0a from './A0a_TempUsers/centralData';
import A1 from './A1_Signin/centralData';
import A1a from './A1a_UserRegister/centralData';
import A2 from './A2_Terms/centralData';
import X_404 from '../X_404/centralData';

export const routes = {
  A0,
  A0a,
  A1,
  A1a,
  A2,

  X_404,
};
