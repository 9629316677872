// ----------- import Packs
import { collection, getDocs } from 'firebase/firestore';

// ----------- import Internals
import { firestoreInit } from '#firebase';

export const asyncGetUsers = async () => {
  // ---------- set Async Call
  const itemsInfo = {};
  const itemsList = [];
  const refDb = collection(firestoreInit, 'users');
  const refCollect = await getDocs(refDb);
  const dataSuccess = {};

  refCollect.forEach(doc => {
    const id = doc.id;
    const condUser = doc.data().devUser;

    if (condUser) {
      itemsInfo[id] = doc.data();
      itemsList.push(id);
    }
  });

  dataSuccess.itemsInfo = itemsInfo;
  dataSuccess.itemsList = itemsList;

  // ----------- set Return
  return dataSuccess;
};
